import { useState, useCallback } from 'react'
import { getIconByName } from '@wiz/icons'
import { go, goreact } from '@wiz/utils'

const config = {
  class: 'go.GraphLinksModel',
  nodeCategoryProperty: 'type',
  linkFromPortIdProperty: 'frompid',
  linkToPortIdProperty: 'topid',
  // nodeDataArray: [
  //   { key: 1, type: 'Query', name: 'Step: Inputs, Outputs, Type, Config' },
  //   { key: 2, type: 'Model', name: 'Model' },
  //   { key: 3, type: 'Write', name: 'Write' },
  //   { key: 4, type: 'Script', name: 'Script' },
  //   { key: 5, type: 'Query', name: 'Query' },
  // ],
  // linkDataArray: [
  //   {
  //     from: 1, frompid: 'output', to: 2, topid: 'input',
  //   },
  //   {
  //     from: 2, frompid: 'output', to: 3, topid: 'input',
  //   },
  // ],
}

const paletteByBlockType = {
  query: '#54B435',
  model: '#F4D160',
  write: '#FA7070',
  script: '#1D5D9B',
  df_save: '#126061',
  plot: '#126061',
}

const titleByType = {
  script: 'scriptTitle',
  query: 'name',
  model: 'scriptTitle',
  write: 'name',
  df_save: 'name',
  plot: 'plot',
}

export const useDiagram = ({ data, diagramRef }) => {
  const initDiagram = () => {
    const $ = go.GraphObject.make
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const diagram =
      $(
        go.Diagram,
        {
          initialContentAlignment: go.Spot.Top,
          initialAutoScale: go.Diagram.UniformToFill,
          layout: $(
            go.LayeredDigraphLayout,
            {
              direction: 90,
              // alignOption: go.LayeredDigraphLayout.AlignAll,
            },
          ),
          'undoManager.isEnabled': true,

        },

      )

    diagram.addDiagramListener('Modified', (e) => {
      const button = document.getElementById('qwe')
      if (button) button.disabled = !diagram.isModified
      const idx = document.title.indexOf('*')
      if (diagram.isModified) {
        if (idx < 0) document.title += '*'
      } else if (idx >= 0) document.title = document.title.slice(0, idx)
    })

    function makePort (name, isInput) {
      const port = $(
        go.Shape,
        'Circle',
        {
          fill: '#555555',
          stroke: null,
          desiredSize: new go.Size(10, 10),
          portId: name,
          // fromMaxLinks: 1,
          toMaxLinks: 1, // don't allow more than one link into a port
          cursor: 'pointer', // show a different cursor to indicate potential link point
        },
      )

      const lab = $(
        go.TextBlock,
        name, // the name of the port
        { font: '7pt sans-serif' },
      )

      const panel = $(
        go.Panel,
        'Vertical',
        { margin: new go.Margin(0, 2) },
      )

      if (isInput) {
        port.toSpot = go.Spot.Top
        port.toLinkable = true
        // lab.margin = new go.Margin(1, 0, 0, 1)
        panel.alignment = go.Spot.TopLeft
        // panel.add(lab)
        panel.add(port)
      } else {
        port.fromSpot = go.Spot.Bottom
        port.fromLinkable = true
        lab.margin = new go.Margin(1, 1, 0, 0)
        panel.alignment = go.Spot.TopRight
        panel.add(lab)
        panel.add(port)
      }
      return panel
    }

    function makeTitle (type) {
      const title = $(
        go.Shape,
        'RoundedRectangle',
        {
          fill: `${paletteByBlockType[type]}99`,
          stroke: null,
          desiredSize: new go.Size(199, 20),
        },
      )

      const text = titleByType[type]

      let lab
      let labExt

      if (type === 'plot') {
        lab = $(
          go.Panel,
          'Horizontal',
          { alignment: go.Spot.Left },
          $(
            go.TextBlock,
            'Plot with',
            {
              font: '7pt sans-serif',
              alignment: go.Spot.Left,
              margin: new go.Margin(0, 4),
              textAlign: 'center',
            },
          ),
          $(
            go.TextBlock,
            {
              font: '7pt sans-serif',
              alignment: go.Spot.Left,
            },
            new go.Binding('text', text),
          ),
        )
      } else if (type === 'df_save') {
        lab = $(
          go.TextBlock,
          'Dataframe Viewer',
          {
            font: '7pt sans-serif',
            alignment: go.Spot.Left,
            margin: new go.Margin(0, 4),
            textAlign: 'center',
          },
        )
      } else {
        lab = $(
          go.TextBlock,
          new go.Binding('text', text).makeTwoWay(),
          {
            font: '7pt sans-serif',
            alignment: go.Spot.Left,
            margin: new go.Margin(0, 4),
            textAlign: 'center',
          },
        )
      }

      const panel = $(
        go.Panel,
        'Auto',
        {
          stretch: go.GraphObject.Fill,
        },
      )

      panel.alignment = go.Spot.TopLeft
      panel.add(title)
      panel.add(lab)
      // if (labExt) {
      //   panel.add(labExt)
      // }
      return panel
    }

    function makeContent ({
      type, config,
    }) {
      let panel
      let icon
      if (type === 'query') {
        const datapointsLength = config.equipments_list?.reduce((acc, val) => {
          acc += val.datapoints.length
          return acc
        }, 0)
        const timeRange = `${config.timeframe.end} -> ${config.timeframe.start}`
        const agg = `${config.aggregations.agg_method} - ${config.aggregations.interval}`

        panel = $(
          go.Panel,
          'Vertical',
          {
            alignment: go.Spot.Left,
            margin: new go.Margin(26, 6),
          },
          $(
            go.Panel,
            'Horizontal',
            {
              alignment: go.Spot.Left,
            },
            $(
              go.TextBlock,
              String(datapointsLength),
              {
                alignment: go.Spot.Left,
              },
            ),
          ),
          $(
            go.Panel,
            'Horizontal',
            $(
              go.TextBlock,
              timeRange,
              {
                alignment: go.Spot.Left,
              },
            ),
          ),
          $(
            go.Panel,
            'Horizontal',
            $(
              go.TextBlock,
              agg,
              {
                alignment: go.Spot.Left,
              },
            ),
          ),
          $(
            go.Panel,
            'Horizontal',
            $(
              go.TextBlock,
              'no filters',
              {
                margin: new go.Margin(0, 0),
                alignment: go.Spot.Left,
              },
            ),
          ),
        )
      } else if (type === 'script') {
        panel = $(
          go.Panel,
          'Vertical',
          {
            alignment: go.Spot.Left,
            margin: new go.Margin(26, 6),
          },
          $(
            go.Panel,
            'Vertical',
            $(
              go.TextBlock,
              config.function,
              {
                alignment: go.Spot.Left,
              },
            ),
          ),
        )
      } else if (type === 'write') {
        let text = ''
        if (config.datapoints) {
          text = Object.values(config.datapoints).join(', ') || 'no datapoints'
        } else if (Object.values(config)?.length) {
          text = Object.values(config)?.filter(item => typeof item === 'string').join(', ') || 'no properties'
        }
        panel = $(
          go.Panel,
          'Vertical',
          {
            alignment: go.Spot.Left,
            margin: new go.Margin(26, 6),
          },
          $(
            go.Panel,
            'Vertical',
            $(
              go.TextBlock,
              text,
              {
                alignment: go.Spot.Left,
              },
            ),
          ),
        )
      } else if (type === 'model') {
        panel = $(
          go.Panel,
          'Vertical',
          {
            alignment: go.Spot.Left,
            margin: new go.Margin(26, 6),
          },
          $(
            go.Panel,
            'Vertical',
            $(
              go.TextBlock,
              config.train_script || '',
              {
                alignment: go.Spot.Left,
              },
            ),
          ),
        )
      }

      if (panel) {
        return panel
      }

      return $(
        go.Panel,
        'Auto',
        {
          stretch: go.GraphObject.Fill,
          alignment: go.Spot.TopLeft,
        },
      )
    }

    function makeStatus (status = 'green') {
      const circle = $(
        go.Shape,
        'RoundedRectangle',
        {
          fill: status,
          stroke: null,
          desiredSize: new go.Size(20, 20),
          margin: new go.Margin(0, 2.5),
        },
      )
      const panel = $(
        go.Panel,
        'Vertical',
        {
          alignment: go.Spot.Right,
          margin: new go.Margin(0, 0, 0, 0),
        },
      )

      panel.add(circle)
      return panel
    }

    function makeTemplate (step, inports, outports, status) {
      const { type, id } = step
      const node = $(
        go.Node,
        'Spot',
        { selectionAdorned: false },
        $(
          go.Panel,
          'Auto',
          { width: NaN, height: NaN, minSize: new go.Size(200, 0) },
          $(
            go.Shape,
            'RoundedRectangle',
            {
              fill: go.Brush.lighten('#fff'),
              stroke: 'gray',
              spot1: go.Spot.TopLeft,
              spot2: go.Spot.BottomRight,
            },
            new go.Binding('stroke', 'isSelected', s => (s ? paletteByBlockType[type] : `${paletteByBlockType[type]}99`)).ofObject(),
          ),
          $(
            go.Panel,
            'Horizontal',
            {
              alignment: go.Spot.TopLeft,
              alignmentFocus: new go.Spot(0, 0, 0, 0),
            },
            makeTitle(type),
          ),
          $(
            go.Panel,
            'Horizontal',
            {
              alignment: go.Spot.TopLeft,
              alignmentFocus: new go.Spot(0, 0, 0, 0),
            },
            makeContent(step),
          ),
        ),
        $(
          go.Panel,
          'Horizontal',
          {
            alignment: go.Spot.Top,
            alignmentFocus: new go.Spot(0.5, 0, 0, 4),
          },
          inports,
        ),
        $(
          go.Panel,
          'Horizontal',
          {
            alignment: go.Spot.Bottom,
            alignmentFocus: new go.Spot(0.5, 1, 0, -4),
          },
          outports,
        ),
        // $(
        //   go.Panel,
        //   'Horizontal',
        //   {
        //     alignment: go.Spot.TopRight,
        //     alignmentFocus: new go.Spot(0, 0, 24, 0),
        //   },
        //   status,
        // ),
      )
      diagram.nodeTemplateMap.set(id, node)
    }

    const nodeDataArray = data.steps?.map((step, idx) => ({
      key: step.id,
      type: step.id,
      name: step.type,
      data: step.config,
      scriptTitle: step.config?.function,
      plot: step.config?.script,
    }))

    const outputs = data.steps.reduce((list, step) => {
      list.push(...step.outputs.map(o => ({ ...o, id: step.id })))
      return list
    }, [])

    const inputs = data.steps.reduce((list, step) => {
      list.push(...step.inputs.map(o => ({ ...o, id: step.id })))
      return list
    }, [])

    const linkDataArray = []
    for (const inp of inputs) {
      for (const outp of outputs) {
        if (outp.dataframe === inp.dataframe) {
          linkDataArray.push({
            from: outp.id, frompid: outp.dataframe, to: inp.id, topid: inp.dataframe,
          })
        }
      }
    }

    data.steps.forEach((step) => {
      makeTemplate(
        step,
        step.inputs.map(input => makePort(input.dataframe, true)),
        step.outputs.map(output => makePort(output.dataframe, false)),
        makeStatus('green'),
      )
    })

    diagram.linkTemplate =
    $(
      go.Link,
      {
        curve: go.Link.Bezier,
        fromEndSegmentLength: 30,
        toEndSegmentLength: 30,
        relinkableFrom: true,
        relinkableTo: true,
      },
      $(go.Shape, { stroke: '#555555', strokeWidth: 2 }),
      // $(go.Shape, { toArrow: "Standard" }),
    //   $(go.Panel, "Auto",
    //   $(go.Shape,{ fill: "yellow", stroke: "gray" }),
    //   $(go.TextBlock, { segmentOffset: new go.Point(0, 10) },
    //     new go.Binding("text", "topid"))
    // )
    // $(go.TextBlock, { segmentOffset: new go.Point(0, 0) }, // this for moving left/right
    //     new go.Binding("text", "topid"))
    )

    diagram.model = go.Model.fromJson({ ...config, nodeDataArray, linkDataArray })
    diagramRef.current = diagram
    return diagram
  }

  return {
    initDiagram,
  }
}
