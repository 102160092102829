export function testJSON (text, validation) {
  try {
    const parsed = JSON.parse(text)
    const isOk = typeof parsed === 'object' && parsed !== null
    if (validation) {
      const isValid = validation(parsed)
      if (isOk && isValid) {
        return true
      }
      return false
    }
    return isOk
  } catch (e) {
    return false
  }
}
