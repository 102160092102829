import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { get, consts } from '@wiz/utils'
import {
  FormField,
  FormFieldInline,
  FormControl,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import CategorieList from '@/hoc/CategorieList'
import UnitList from '@/hoc/UnitList'
import BusinessTypeList from '@/hoc/BusinessTypeList'
import InputModeList from '@/hoc/InputModeList'
import SelectLocation from '@/components/Form/SelectLocation'
import SelectIcon from '@/containers/Form/SelectIcon'
// import SelectBatchDataSource from '@/containers/Form/SelectBatchDataSource'
import SelectLabel from '@/components/Form/SelectLabel'
import SelectTwin from '@/components/Form/SelectTwin'
import SelectGroupSystem from '@/components/Form/SelectGroupSystem'
import Select from '@/components/Form/Select'
import { testJSON } from '@/utils/json'

export default function Fields ({
  scope,
  exclude,
}) {
  const intl = useIntl()
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()

  const [
    id,
    // isBatch,
    businessType,
    inputMode,
  ] = watch([
    `${scope}.id`,
    // `${scope}.batch`,
    `${scope}.businessType`,
    `${scope}.inputMode`,
  ])

  const isVirtual = consts.SensorBusinessTypesVirtual.includes(businessType)
  const isUserCreated = !id || consts.SensorBusinessTypesUserCreate.includes(businessType)
  const isAutoCreated = id && !consts.SensorBusinessTypesUserCreate.includes(businessType)
  const inputModes = (
    consts.SensorBusinessTypeInputModes[businessType] ||
    consts.SensorInputModes
  )
  const isEvent = businessType === consts.SensorBusinessType.Event

  return (
    <>
      <FormField
        label={intl.t('sensors.form.fields.businessType')}
        errors={get(errors, `${scope}.businessType`)}
        description={() => (
          <>
            <div>{intl.t('sensors.form.fields.businessTypeDescr')}</div>
            <div>
              {do {
                if (businessType === consts.SensorBusinessType.Telemetry) {
                  intl.t('sensors.form.fields.businessTypeTelemetryDescr')
                } else if (businessType === consts.SensorBusinessType.SetPoint) {
                  intl.t('sensors.form.fields.businessTypeSetPointDescr')
                } else if (businessType === consts.SensorBusinessType.Logical) {
                  intl.t('sensors.form.fields.businessTypeLogicalDescr')
                } else if (businessType === consts.SensorBusinessType.Measurement) {
                  intl.t('sensors.form.fields.businessTypeMeasurementDescr')
                } else if (businessType === consts.SensorBusinessType.Event) {
                  intl.t('sensors.form.fields.businessTypeEventDescr')
                } else if (businessType === consts.SensorBusinessType.Text) {
                  intl.t('sensors.form.fields.businessTypeTextDescr')
                }
              }}
            </div>
          </>
        )}
      >
        <Controller
          name={`${scope}.businessType`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              disabled={exclude?.includes(`${scope}.businessType`)}
              ListSource={BusinessTypeList}
              filters={{
                userCreated: isUserCreated,
                autoCreated: isAutoCreated,
              }}
              invalid={fieldState.invalid}
              clearable={false}
              onChange={(data) => {
                field.onChange(data)
                const nextInputMode = (
                  consts.SensorBusinessTypeInputModes[data] ||
                  consts.SensorInputModes
                )
                const defaultInputMode = nextInputMode[0]
                setValue(`${scope}.inputMode`, defaultInputMode, {
                  shouldDirty: true,
                })

                if (data !== consts.SensorBusinessType.Event) {
                  setValue(`${scope}.groupSystemId`, null, {
                    shouldDirty: true,
                  })
                }
              }}
            />
          )}
        />
      </FormField>

      {isEvent ? (
        <FormField
          label={intl.t('sensors.form.fields.groupSystems')}
          errors={get(errors, `${scope}.groupSystemId`)}
          description={intl.t('sensors.form.fields.groupSystemsDescr')}
        >
          <Controller
            name={`${scope}.groupSystemId`}
            rules={{
              required: intl.t('form.errors.fieldRequired'),
            }}
            render={({ field, fieldState }) => (
              <SelectGroupSystem
                {...field}
                invalid={fieldState.invalid}
                placeholder="--"
              />
            )}
          />
        </FormField>
      ) : null}

      <FormField
        label={intl.t('sensors.form.fields.inputMode')}
        errors={get(errors, `${scope}.inputMode`)}
        description={() => (
          <>
            <div>{intl.t('sensors.form.fields.inputModeDescr')}</div>
            <div>
              {do {
                if (inputMode === consts.SensorInputMode.Manual) {
                  intl.t('sensors.form.fields.inputModeManualDescr')
                } else if (inputMode === consts.SensorInputMode.Automatic) {
                  intl.t('sensors.form.fields.inputModeAutomaticDescr')
                } else if (inputMode === consts.SensorInputMode.ManualAndAutomatic) {
                  intl.t('sensors.form.fields.inputModeManualAndAutomaticDescr')
                }
              }}
            </div>
          </>
        )}
      >
        <Controller
          name={`${scope}.inputMode`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={InputModeList}
              disabled={!(
                businessType &&
                inputModes.length > 1
              )}
            />
          )}
        />
      </FormField>

      <FormField
        label={isVirtual ?
          intl.t('sensors.form.fields.virtualHardwareId') :
          intl.t('form.fields.hardwareId')}
        description={isVirtual ?
          intl.t('sensors.form.fields.virtualHardwareIdDescr') :
          intl.t('sensors.form.fields.hardwareIdDescr')}
        errors={get(errors, `${scope}.hardwareId`)}
      >
        <FormControl
          name={`${scope}.hardwareId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            validate: (value) => {
              if (value.length < 2 || value.length > 128) {
                const title = isVirtual ?
                  intl.t('sensors.form.fields.virtualHardwareId') :
                  intl.t('form.fields.hardwareId')
                return `${title} should be more than 3 symbols`
              }
              return true
            },
          }}
          disabled={!!id}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('sensors.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.category')}
        description={intl.t('sensors.form.fields.categoryDescr')}
        errors={get(errors, `${scope}.categoryId`)}
      >
        <Controller
          name={`${scope}.categoryId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={CategorieList}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.minValue')}
        description={intl.t('sensors.form.fields.minValueDescr')}
        errors={get(errors, `${scope}.minValue`)}
      >
        <FormControl
          type="number"
          scale={6}
          name={`${scope}.minValue`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.maxValue')}
        description={intl.t('sensors.form.fields.maxValueDescr')}
        errors={get(errors, `${scope}.maxValue`)}
      >
        <FormControl
          type="number"
          scale={6}
          name={`${scope}.maxValue`}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.frequency')}
        description={intl.t('sensors.form.fields.frequencyDescr')}
        errors={get(errors, `${scope}.frequency`)}
      >
        <FormControl
          type="number"
          name={`${scope}.frequency`}
          min={0}
          max={2147483647} // int32 max
          rules={{
            validate: (value) => {
              if (value < 0) {
                return intl.t('form.errors.negativeValue')
              }
              return true
            },
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.unit')}
        description={intl.t('sensors.form.fields.unitDescr')}
        errors={get(errors, `${scope}.unitId`)}
      >
        <Controller
          name={`${scope}.unitId`}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              invalid={fieldState.invalid}
              ListSource={UnitList}
              placeholder="--"
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.validity')}
        description={intl.t('sensors.form.fields.validityDescr')}
        errors={get(errors, `${scope}.validity`)}
      >
        <FormControl
          type="duration"
          name={`${scope}.validity`}
          parts="years,months,weeks,days,hours,minutes,seconds"
          clearable
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.description')}
        description={intl.t('sensors.form.fields.descriptionDescr')}
        errors={get(errors, `${scope}.description`)}
      >
        <FormControl
          type="textarea"
          name={`${scope}.description`}
        />
      </FormField>

      {/* <FormFieldInline
        label={intl.t('form.fields.batch')}
        description={intl.t('sensors.form.fields.batchDescr')}
        errors={get(errors, `${scope}.batch`)}
      >
        <FormControl
          type="checkbox"
          name={`${scope}.batch`}
          className="ms-2"
        />
      </FormFieldInline> */}

      {/* <FormField
        label={intl.t('form.fields.batchDataSource')}
        description={intl.t('sensors.form.fields.batchDataSourceDescr')}
        errors={get(errors, `${scope}.batchDataSource`)}
        disabled={isBatch}
      >
        <Controller
          name={`${scope}.batchDataSource`}
          render={({ field, fieldState }) => (
            <SelectBatchDataSource
              {...field}
              invalid={fieldState.invalid}
              placeholder="--"
              disabled={isBatch}
            />
          )}
        />
      </FormField> */}

      <FormFieldInline
        label={intl.t('form.fields.color')}
        description={intl.t('sensors.form.fields.colorDescr')}
        errors={get(errors, `${scope}.color`)}
      >
        <FormControl
          type="color"
          name={`${scope}.color`}
          clearable
        />
      </FormFieldInline>

      <FormField
        label={intl.t('form.fields.icon')}
        description={intl.t('sensors.form.fields.iconDescr')}
        errors={get(errors, `${scope}.icon`)}
      >
        <Controller
          name={`${scope}.icon`}
          render={({ field, fieldState }) => (
            <SelectIcon
              {...field}
              invalid={fieldState.invalid}
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.location')}
        description={intl.t('sensors.form.fields.locationDescr')}
      >
        <SelectLocation scope={scope} />
      </FormField>

      <FormField
        label={intl.t('form.fields.labels')}
        description={intl.t('sensors.form.fields.labelsDescr')}
        errors={get(errors, 'labels')}
      >
        <Controller
          name="labels"
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.labelsPlaceholder')}
              multiselect
            />
          )}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.twin')}
        description={intl.t('sensors.form.fields.twinDescr')}
        errors={get(errors, `${scope}.twinId`)}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              filters={{
                types: [
                  consts.TwinType.Area,
                  consts.TwinType.Equipment,
                  consts.TwinType.Machine,
                  consts.TwinType.Flow,
                ],
              }}
            />
          )}
        />
      </FormField>
      <FormFieldInline
        label={intl.t('form.fields.extraProperties')}
        description={intl.t('scripts.form.fields.extraPropertiesDescr')}
        errors={get(errors, `${scope}.extraProperties`)}
        vertical
        complex
      >
        <FormControl
          type="code"
          title={intl.t('form.fields.extraProperties')}
          description={intl.t('scripts.form.fields.extraPropertiesDescr')}
          name={`${scope}.extraProperties`}
          lang="json"
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (str.length && !testJSON(str, val => !Array.isArray(val))) {
                return intl.t('form.errors.mustBeJson')
              }
              if (value && !str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value?.length > 8000) {
                return intl.t('form.errors.fieldMaxlen', { max: 8000 })
              }
              return true
            },
          }}
        />
      </FormFieldInline>
    </>
  )
}

Fields.propTypes = {
  scope: PropTypes.string.isRequired,
  // validates: PropTypes.object,
  exclude: PropTypes.arrayOf(PropTypes.string),
}

Fields.defaultProps = {
  // validates: undefined,
  exclude: [],
}
