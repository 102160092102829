import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import { set, consts } from '@wiz/utils'
import { Q, dbProvider, checkUniqueField } from '@wiz/store'
import { testJSON } from '@/utils/json'
import { intl } from '@/i18n'

async function checkQualityRecords (labels) {
  if (!labels.length) {
    return false
  }

  const count = await dbProvider.database.collections.get('quality_data')
    .query(
      Q.experimentalNestedJoin(
        'quality_data_templates',
        'quality_data_template_measurements',
      ),
      Q.on(
        'quality_data_templates',
        Q.on('quality_data_template_measurements', 'label_id', Q.oneOf(labels)),
      ),
    )
    .fetchCount()

  return count > 0
}

export default async function resolver (data) {
  const errors = {}
  const row = data?.sensor || {}
  const labels = data?.labels || []

  const prevLabels = row.id ? (await dbProvider.database.collections.get('rel_sensors_labels').query(
    Q.where('sensor_id', row.id),
  ).fetch()).map(item => item.labelId) : []
  const removedLabels = difference(prevLabels, labels)
  const createdLabels = difference(labels, prevLabels)
  const updatedLabels = intersection(prevLabels, labels)
  const hasRemovedLabelsQR = await checkQualityRecords(removedLabels)
  const hasCreatedLabelsQR = await checkQualityRecords(createdLabels)
  const hasUpdatedLabelsQR = await checkQualityRecords(updatedLabels)

  if (hasCreatedLabelsQR) {
    set(errors, 'labels', {
      type: 'addLabelWithQualityData',
      message: intl.t('sensors.form.errors.addLabelWithQualityData'),
    })
  } else if (hasRemovedLabelsQR) {
    set(errors, 'labels', {
      type: 'removeLabelWithQualityData',
      message: intl.t('sensors.form.errors.removeLabelWithQualityData'),
    })
  }

  if (hasUpdatedLabelsQR) {
    const prevSensor = (await dbProvider.database.collections.get('sensors').query(
      Q.where('id', row.id),
    ).fetch())[0]

    if (prevSensor.hardwareId !== row.hardwareId) {
      set(errors, 'sensor.hardwareId', {
        type: 'updateHardwareIdWithQualityData',
        message: intl.t('sensors.form.errors.updateHardwareIdWithQualityData'),
      })
    }

    if (prevSensor.inputMode !== row.inputMode) {
      set(errors, 'sensor.inputMode', {
        type: 'updateInputModeWithQualityData',
        message: intl.t('sensors.form.errors.updateInputModeWithQualityData'),
      })
    }
  }

  if (row.hardwareId) {
    const isUniqHardwareId = await checkUniqueField(
      row,
      'hardwareId',
      dbProvider.database.collections.get('sensors').query(),
    )

    if (!isUniqHardwareId) {
      set(errors, 'sensor.hardwareId', {
        type: 'unique',
        message: intl.t('sensors.form.errors.hardwareIdExists'),
      })
    }
  }

  if (row.extraProperties) {
    const str = String(row.extraProperties || '').trim()
    if (str.length && !testJSON(str, val => !Array.isArray(val))) {
      set(errors, 'sensor.extraProperties', {
        type: 'mustBeJson',
        message: intl.t('form.errors.mustBeJson'),
      })
    }
  }

  if (row.businessType === consts.SensorBusinessType.Event) {
    if (!row.groupSystemId) {
      set(errors, 'sensor.groupSystemId', {
        type: 'required',
        message: intl.t('form.errors.fieldRequired'),
      })
    }
  }

  return {
    errors,
  }
}
